<template>
  <v-app>
    <Tip :dialog="dialog" :tip="timeOutTip" @closeForm="closeTip"></Tip>
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner2 pt-4">
        <v-card class="px-6 py-6 mt-12">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="11">
              <div class="py-6">
                <div class="mb-6" style="font-size: 20px; font-weight: bold">
                  <span>新規会員登録</span>
                </div>
                <v-spacer></v-spacer>
                <template v-if="apierror.status === 'error'">
                  <div v-for="msg of apierror.messages" :key="msg">
                    <v-row class="ml-6 mb-3 ma-3">
                      <span style="color: red">* {{ msg }} </span>
                    </v-row>
                  </div>
                </template>
                <v-spacer></v-spacer>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3">
                    <div>
                      <span>お名前</span>
                      <span
                        class="ml-1 pr-1"
                        style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                      >
                        必須</span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div class="d-flex">
                      <v-text-field
                        v-model="$v.Form.mbr_sei.$model"
                        :error-messages="seiErrors"
                        placeholder="姓"
                        hide-details="auto"
                        outlined
                        dense
                        color="#ff6e40"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="$v.Form.mbr_mei.$model"
                      :error-messages="meiErrors"
                      placeholder="名"
                      hide-details="auto"
                      outlined
                      dense
                      color="#ff6e40"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3">
                    <div>
                      <span>フリガナ</span>
                      <!-- <span
                        class="ml-1 px-1"
                        style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                      >必須</span> -->
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="$v.Form.mbr_sei_kana.$model"
                      :error-messages="seiKanaErrors"
                      placeholder="セイ"
                      hide-details="auto"
                      outlined
                      dense
                      color="#ff6e40"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div class="d-flex">
                      <v-text-field
                        v-model="$v.Form.mbr_mei_kana.$model"
                        :error-messages="meiKanaErrors"
                        placeholder="メイ"
                        hide-details="auto"
                        outlined
                        dense
                        color="#ff6e40"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3">
                    <div>
                      <span>生年月日</span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      color="#ff6e40"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="Form.mbr_dob"
                          placeholder="年-月-日"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          color="#ff6e40"
                          hide-details
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="Form.mbr_dob" color="#ff6e40" @input="menu = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="4"></v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3">
                    <div>
                      <span>性別</span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-radio-group v-model="Form.mbr_gender" row hide-details class="d-flex">
                      <v-radio label="男性" :value="1" color="#ff6e40"></v-radio>
                      <v-radio label="女性" :value="2" color="#ff6e40"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" sm="4"></v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3">
                    <div>
                      <span>郵便番号</span>
                      <span
                        class="ml-1 pr-1"
                        style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                      >
                        必須</span>
                    </div>
                    <div style=" font-size: 12px;">
                      半角-ハイフンなし
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="$v.Form.mbr_zip.$model"
                      :error-messages="zipErrors"
                      outlined
                      dense
                      color="#ff6e40"
                      placeholder="1000001"
                      hide-details="auto"
                      :loading="loading"
                      @input="handleZipInput"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="d-flex justify-start">
                    <!-- <v-btn depressed style="height: 40px; background-color: #ff6e40" @click="getAddress(Form)">
                      <span style="color: #fff">住所を検索</span>
                    </v-btn> -->
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3">
                    <div>
                      <span>住所１</span>
                      <span
                        class="ml-1 pr-1"
                        style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                      >
                        必須</span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="$v.Form.mbr_address1.$model"
                      :error-messages="mbr_address1Errors"
                      placeholder="市区町村番号(例：東京都千代田区大手町1-2-3)"
                      hide-details="auto"
                      outlined
                      dense
                      color="#ff6e40"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3"></v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="$v.Form.mbr_address2.$model"
                      :error-messages="mbr_address2Errors"
                      placeholder="建物名・部屋番号"
                      hide-details="auto"
                      outlined
                      dense
                      color="#ff6e40"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3">
                    <div>
                      <span>電話番号</span>
                      <span
                        class="ml-1 pr-1"
                        style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                      >
                        必須</span>
                    </div>
                    <div style=" font-size: 12px;">
                      半角-ハイフンなし
                    </div>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="$v.Form.mbr_mobile.$model"
                      :error-messages="mobileErrors"
                      placeholder="09012345678"
                      hide-details="auto"
                      outlined
                      dense
                      color="#ff6e40"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3">
                    <div>
                      <span>パスワード</span>
                      <span
                        class="ml-1 pr-1"
                        style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                      >
                        必須</span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="$v.mbr_password.$model"
                      :error-messages="passwordErrors"
                      :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPwd ? 'text' : 'password'"
                      autocomplete="new-password"
                      placeholder="半角英数字記号6-16文字"
                      hide-details="auto"
                      outlined
                      dense
                      color="#ff6e40"
                      @click:append="showPwd = !showPwd"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3">
                    <div>
                      <span>パスワード確認</span>
                      <span
                        class="ml-1 pr-1"
                        style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                      >
                        必須</span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="$v.confirm_password.$model"
                      :error-messages="confirmPasswordErrors"
                      :append-icon="showConfirmPwd ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showConfirmPwd ? 'text' : 'password'"
                      autocomplete="check-password"
                      placeholder="半角英数字記号6-16文字"
                      hide-details="auto"
                      outlined
                      dense
                      color="#ff6e40"
                      @click:append="showConfirmPwd = !showConfirmPwd"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>

                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3">
                    <div>
                      <span>備考</span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-textarea
                      v-model="$v.Form.mbr_nickname.$model"
                      hide-details="auto"
                      color="#ff6e40"
                      outlined
                      :counter="45"
                      :error-messages="nicknameErrors"
                      dense
                      rows="2"
                      placeholder="備考"
                    ></v-textarea>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <div class="d-flex justify-end mt-12">
                  <v-btn outlined width="139px" @click="back">
                    <span>取消</span>
                  </v-btn>
                  <v-btn color="#ff6e40" class="ml-3" width="139px" depressed @click="submit(Form)">
                    <span style="color: #fff">登録</span>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card>
      </div>
    </div>
    <overlays :is-show="OverlayStatus"></overlays>
    <v-dialog v-model="dialogError" width="400px" persistent>
      <v-card height="180px" class="d-flex align-center justify-center pb-3">
        <div>
          <div class="mt-6 px-6" style="text-align: center; font-weight: bold; font-size: 16px; word-break: keep-all">
            <span>{{ dialogMessage }}</span>
          </div>
          <div class="d-flex justify-center mt-6">
            <v-btn color="#ff6e40" width="80px" class="mr-3" depressed @click="closeDialogError1">
              <span style="color: #fff">新規登録</span>
            </v-btn>
            <v-btn color="#ff6e40" width="80px" depressed @click="closeDialogError2">
              <span style="color: #fff">ログイン</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
/* eslint-disable */
import { mapState, mapMutations, mapActions } from 'vuex'
import { required, maxLength, minLength, sameAs } from 'vuelidate/lib/validators'
import { helpers } from '@vuelidate/validators'
import Overlays from '@/components/Overlays.vue'
import Tip from './TipRegister.vue'
/* eslint-disable */

const allowedKana = helpers.regex(/^[ァ-ヶー]*$/)
const allowedChar = helpers.regex(/^[0-9a-zA-Z]+[0-9a-zA-Z-_()]*$/)
const validMobile = helpers.regex(/^0[0-9]{9,10}$/)
const validZip = helpers.regex(/^[0-9]{3}[-]{0,1}[0-9]{4}$/)
export default {
  components: {
    Overlays,
    Tip,
  },
  data: () => ({
    loading: false,
    dialogError: false,
    dialogMessage: '',
    dialog: false,
    timeOutTip: '送信がタイムアウトしました。',
    submitStatus: false,
    menu: false,
    showPwd: false,
    showConfirmPwd: false,
    mbr_password: '',
    confirm_password: '',
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    Form: {
      mbr_sei: '',
      mbr_mei: '',
      mbr_sei_kana: '',
      mbr_mei_kana: '',
      mbr_nickname: '',
      mbr_dob: '',
      mbr_gender: '',
      mbr_zip: '',
      prefcode: '',
      mbr_address1: '',
      mbr_address2: '',
      mbr_mobile: '',
      mbr_password: '',
    },
  }),
  validations: {
    Form: {
      mbr_sei: {
        required,
        maxLength: maxLength(10),
      },
      mbr_mei: {
        required,
        maxLength: maxLength(10),
      },
      mbr_sei_kana: {
        // required,
        maxLength: maxLength(10),
        allowedKana,
      },
      mbr_mei_kana: {
        // required,
        maxLength: maxLength(10),
        allowedKana,
      },
      mbr_zip: {
        required,
        validZip,
      },
      mbr_address1: {
        required,
        maxLength: maxLength(100),
      },
      mbr_address2: {
        maxLength: maxLength(100),
      },
      mbr_mobile: {
        required,
        maxLength: maxLength(13),
        validMobile,
      },
      mbr_nickname: {
        maxLength: maxLength(45),
      },
    },
    mbr_password: {
      required,
      maxLength: maxLength(16),
      minLength: minLength(6),
    },
    confirm_password: {
      required,
      sameAs: sameAs('mbr_password'),
      maxLength: maxLength(16),
      minLength: minLength(6),
    },
  },
  computed: {
    ...mapState('app', ['OverlayStatus']),
    ...mapState('register', ['memberInfo']),
    seiErrors() {
      const errors = []
      if (!this.$v.Form.mbr_sei.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_sei.maxLength && errors.push('最大10文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_sei.required && errors.push('必須項目')

      return errors
    },
    meiErrors() {
      const errors = []
      if (!this.$v.Form.mbr_mei.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_mei.maxLength && errors.push('最大10文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_mei.required && errors.push('必須項目')

      return errors
    },
    seiKanaErrors() {
      const errors = []
      if (!this.$v.Form.mbr_sei_kana.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_sei_kana.maxLength && errors.push('最大10文字までです！')
      // // eslint-disable-next-line no-unused-expressions
      // !this.$v.Form.mbr_sei_kana.required && errors.push('必須項目')

      !this.$v.Form.mbr_sei_kana.allowedKana && errors.push('全角カナを入力してください。')

      return errors
    },
    meiKanaErrors() {
      const errors = []
      if (!this.$v.Form.mbr_mei_kana.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_mei_kana.maxLength && errors.push('最大10文字までです！')
      // // eslint-disable-next-line no-unused-expressions
      // !this.$v.Form.mbr_mei_kana.required && errors.push('必須項目')

      !this.$v.Form.mbr_mei_kana.allowedKana && errors.push('全角カナを入力してください。')

      return errors
    },
    nicknameErrors() {
      const errors = []
      if (!this.$v.Form.mbr_nickname.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_nickname.maxLength && errors.push('最大45文字までです!')

      return errors
    },
    dobErrors() {
      const errors = []
      if (!this.$v.Form.mbr_dob.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_dob.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      return errors
    },
    genderErrors() {
      const errors = []
      if (!this.$v.Form.mbr_gender.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_gender.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      return errors
    },
    zipErrors() {
      const errors = []
      if (!this.$v.Form.mbr_zip.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_zip.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_zip.validZip && errors.push('数字7桁、半角―ハイフンなしで入力して下さい。')
      return errors
    },
    mbr_address1Errors() {
      const errors = []
      if (!this.$v.Form.mbr_address1.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_address1.required && errors.push('必須項目')

      !this.$v.Form.mbr_address1.maxLength && errors.push('最大100文字までです。')
      // eslint-disable-next-line no-unused-expressions
      return errors
    },
    mbr_address2Errors() {
      const errors = []
      if (!this.$v.Form.mbr_address2.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_address2.maxLength && errors.push('最大100文字までです。')
      // eslint-disable-next-line no-unused-expressions
      return errors
    },
    mobileErrors() {
      const errors = []
      if (!this.$v.Form.mbr_mobile.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_mobile.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.mbr_mobile.validMobile && errors.push('0から始まる10桁または11桁の番号を入力して下さい。')

      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.mbr_password.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.mbr_password.maxLength && errors.push('最大16文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.mbr_password.minLength && errors.push('最小6文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.mbr_password.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      return errors
    },

    confirmPasswordErrors() {
      const errors = []
      if (!this.$v.confirm_password.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.confirm_password.maxLength && errors.push('最大16文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.confirm_password.minLength && errors.push('最小6文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.confirm_password.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.confirm_password.sameAs && errors.push('上記「パスワード」と一致していません。')

      return errors
    },
  },
  created() {
    this.clearForm()
    this.setOverlayStatus(true)
    this.getMemberInfo(this.$route.params.id)
      .then(() => {
        this.Form = this.memberInfo
        this.setOverlayStatus(false)
      })
      .catch(error => {
        this.dialogMessage = error.response.data.message[0]
        this.dialogError = true
        this.setOverlayStatus(false)
      })
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('memberStore', ['registerMember']),
    ...mapActions('register', ['getMemberInfo']),

    closeDialogError1() {
      this.dialogError = false
      this.$router.push('/register')
    },
    closeDialogError2() {
      this.dialogError = false
      this.$router.push('/login')
    },
    handleZipInput(val) {
      if (val.length === 7) {
        this.getAddress()
      }
    },
    clearZipError() {
      this.apierror.messages = ''
      this.apierror.status = ''
      this.apierror.code = ''
    },
    setZipError() {
      this.apierror.messages = ['正しい郵便番号を入力してください']
      this.apierror.status = 'error'
      this.apierror.code = 'zip'
    },
    getAddress(Form) {
      const api = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode='
      const url = api + this.Form.mbr_zip
      this.clearZipError()
      this.Form.prefcode = ''
      this.Form.mbr_address1 = ''

      // const str = JSON.stringify(url)
      this.loading = true
      fetch(url)
        .then(response => response.json())
        .then(data => {
          if (data.status === 400) {
            // エラー時
            this.setZipError()
            console.error('error1', this.error)
          } else if (data.results === null) {
            this.setZipError()
            console.error('error2', this.error)
          } else {
            this.Form.prefcode = data.results[0].prefcode
            this.Form.mbr_address1 = data.results[0].address1 + data.results[0].address2 + data.results[0].address3
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    submit(Form) {
      this.$v.$touch()
      this.apierror.status = ''
      this.apierror.messages = []
      if (Form.prefcode === null) {
        this.apierror.status = 'error'
        this.apierror.messages.push('都道府県の確認ができませんでした。郵便番号を確認してください。')
        return
      }

      if (!this.$v.$invalid) {
        this.submitStatus = true
        Form.mbr_password = this.mbr_password
        Form.mbr_zip = Form.mbr_zip.replace('-', '')
        this.setOverlayStatus(true)
        this.registerMember(Form)
          .then(() => {
            this.back()
          })
          .catch(error => {
            this.dialog = true
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          })
          .finally(() => {
            this.submitStatus = false
            this.setOverlayStatus(false)
          })
      }
    },

    clearForm() {
      this.Form = {
        mbr_sei: '',
        mbr_mei: '',
        mbr_sei_kana: '',
        mbr_mei_kana: '',
        mbr_nickname: '',
        mbr_dob: '',
        mbr_gender: '',
        mbr_zip: '',
        prefcode: '',
        mbr_address1: '',
        mbr_address2: '',
        mbr_mobile: '',
        mbr_password: '',
      }
      this.mbr_password = ''
      this.confirm_password = ''
    },

    back() {
      this.$router.push('/')
    },
    closeTip() {
      this.dialog = false
      this.$router.push('/')
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
