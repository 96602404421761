<template>
  <v-dialog :value="dialog" width="600px">
    <v-card class="d-flex align-center justify-center py-6 px-6">
      <div>
        <!-- <div class="mt-4" style="text-align: center; font-weight: bold; font-size: 20px">
          <span
            >新規会員 <br v-if="!$vuetify.breakpoint.smAndUp" />
            仮登録メール送信完了</span
          >
        </div> -->
        <div class="mt-4" style="text-align: center; font-weight: bold; font-size: 16px">
          <span>ご登録いただいたメールアドレス宛にメールを送信しました。</span>
        </div>
        <div class="mt-6" style="text-align: left; font-size: 14px">
          <span>メールにURLが記載されております</span>
          <br />
          <span>URLへアクセスし、本登録の手続きを進めてください</span>
          <br />
          <br />
          <span>※新規会員登録の手続きは完了していません</span>
          <br />
          <span>※しばらくたってもメールが届かない場合は、設定したメールアドレスに間違いがないかをご確認ください。</span>
          <br />
          <span
            >※24時間以内にURLへアクセスがなかった場合、有効期限切れとなりますのでご注意ください。その場合は再度、最初から手続きをお願い致します。</span
          >
        </div>
        <div class="d-flex justify-center mt-8">
          <v-btn color="#ff6e40" width="80px" depressed @click="closeForm">
            <span style="color: #fff">OK</span>
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    icons: {
      mdiClose,
    },
  }),
  methods: {
    closeForm() {
      this.$emit('closeForm')
    },
  },
}
</script>
